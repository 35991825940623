import React from 'react';

import {
  WorkWithUs,
  Chef,
  Footer,
  Gallery,
  Header,
  Booktable,
} from './container';
import { Navbar } from './components';
import './App.css';

const App = () => (
  <div>
    <Navbar />
    <Header />
    {/* <SpecialMenu /> */}
    <Chef />
    <Booktable />
    <WorkWithUs />
    <Gallery />
    {/* <FindUs /> */}
    <Footer />
  </div>
);

export default App;
