import chef from '../assets/chef2.png';
import workwithus from '../assets/workwithus2.png';
import gallery01 from '../assets/IMG_0493.jpeg';
import gallery02 from '../assets/IMG_5207.jpeg';
import gallery03 from '../assets/IMG_0488.jpeg';
import gallery04 from '../assets/IMG_0491.jpeg';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import findus from '../assets/contact.png';
import laurels from '../assets/booktable2.png';
import texture from '../assets/Texture_logo_pixel_web_black.png';
import privateroom from '../assets/privateroom1.png';
import nelson from '../assets/IMG_99202.jpg';

export default {
  chef,
  workwithus,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  overlaybg,
  spoon,
  findus,
  laurels,
  texture,
  privateroom,
  nelson,
};
