import React from 'react';
import { images } from '../../constants';
import './Header.css';

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info_header">
      <img src={images.texture} className="app__header-logo" />
      <button type="button" className="custom__button" style={{ marginBottom: '1rem' }}>
        <a href="TextureMenu.pdf" target="_blank">Explore Menu</a>
      </button>
      <button type="button" className="custom__button">
        <a href="Winelist.pdf" target="_blank">Explore Winelist</a>
      </button>
    </div>
  </div>
);

export default Header;
