import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import images from '../../constants/images';

import './Navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.texture} alt="app__logo" />
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans">
          <a href="#home">Home</a>
        </li>
        <li className="p__opensans">
          <a href="TextureMenu.pdf">Menu</a>
        </li>
        <li className="p__opensans">
          <a href="#chef">The chef</a>
        </li>
        <li className="p__opensans">
          <a href="#booktable">Book a table</a>
        </li>
        <li className="p__opensans">
          <a href="#work">Work with us</a>
        </li>
        <li className="p__opensans">
          <a href="#contact">Contact</a>
        </li>
      </ul>

      <div className="app__navbar-login">
        <a href="https://giftcard.superbexperience.com/restauranttexture" className="p__opensans">Buy a gift card</a>
      </div>

      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu
          color="#000000"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu
              fontSize={27}
              className="overlay__close"
              onClick={() => setToggleMenu(false)}
            />
            <ul className="app__navbar-smallscreen_links">
              <li>
                <a href="#home" onClick={() => setToggleMenu(false)}>
                  Home
                </a>
              </li>
              <li>
                <a href="TextureMenu.pdf" onClick={() => setToggleMenu(false)}>
                  Menu
                </a>
              </li>
              <li>
                <a href="#chef" onClick={() => setToggleMenu(false)}>
                  The chef
                </a>
              </li>
              <li>
                <a href="#booktable" onClick={() => setToggleMenu(false)}>
                  Book a table
                </a>
              </li>
              <li>
                <a href="#work" onClick={() => setToggleMenu(false)}>
                  Work with us
                </a>
              </li>
              <li>
                <a href="#privateroom" onClick={() => setToggleMenu(false)}>
                  Chambre séparée
                </a>
              </li>
              <li>
                <a href="#contact" onClick={() => setToggleMenu(false)}>
                  Contact
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
