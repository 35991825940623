import React from 'react';

import { images } from '../../constants';
import './Booktable.css';

const Booktable = () => (
  <div className="app__bg app__wrapper section__padding" id="booktable">
    <div className="app__wrapper_info">
      <h1 className="headtext__cormorant">Book a Table</h1>
      {/* <p className="p__opensans" style={{ margin: '2rem 0' }}>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi sed
        porro ex esse, consequuntur ut deserunt perferendis sequi consequatur
        necessitatibus, enim ad sint fugiat laudantium repellat officiis.
        Eveniet, assumenda tenetur.
      </p> */}
      <a href="https://restauranttexture.superbexperience.com/reserve/experience">
        <button
          type="button"
          className="custom__button"
          style={{ marginTop: '2rem' }}
        >
          Book a table
        </button>
      </a>
    </div>

    <div className="app__wrapper_img">
      <img src={images.laurels} alt="laurels_img" />
    </div>
  </div>
);

export default Booktable;
