import React from 'react';
import { images } from '../../constants';
import './Gallery.css';

const Gallery = () => (
  <div className="app__bg app__wrapper section__padding" id="privateroom">
    <div className="app__wrapper_info">
      <h1 className="headtext__cormorant">Chambre séparée</h1>
      <p className="p__opensans">
        Do you want an exclusive and different day with your customers or team?
        We offer a comfortably furnished party rooms that set the stage for a delightful lunch or evening in private
        settings up to 10 people allowing you to relish eachothers company and at the same time have a gastronomic
        experience with a focus on best ingredients. You just have to show up and enjoy the day, we will take care of
        all the practicalities. Please contact us by email: <br />
        albert.dalum@texturerestaurant.com
      </p>
      <button
        type="button"
        className="custom__button"
        style={{ marginTop: '2rem' }}
      >
        View More
      </button>
    </div>

    <div className="app__wrapper_img">
      <img src={images.privateroom} alt="privateroom_img" />
    </div>
  </div>
);

export default Gallery;
