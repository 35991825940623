import React from 'react';

import { images } from '../../constants';
import './Chef.css';

const Chef = () => (
  <div className="app__wrapper-reverse section__padding" id="chef">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.chef} alt="chef_image" />
    </div>
    <div className="app__wrapper_info">
      <h1 className="headtext__cormorant">The chef</h1>
      <div className="app__wrapper_info">
        <p className="p__opensans">
          Karim was born in Marseille France with some Italian roots. He has
          more than 28 years experience in Gastronomie with a background in 2
          and 3 Michelin Star restaurants such as Restaurant Escale and in
          Carryle Rouet Marco Pierre White and also Oak Room London. Karim
          moved to Sweden and opened Restaurant Ambiance. He was the first to
          gain a Michelin Star in Malmö 2015 at Ambiance a Vindakra. He sold
          Ambiance in October 2016. Karim Opened Restaurant Sture, Malmö in
          November 2016 and gained 1 Michelin Star three months later.
        </p>
      </div>
    </div>
  </div>
);

export default Chef;
