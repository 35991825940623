import React from 'react';

import { images } from '../../constants';
import './WorkWithUs.css';

const WorkWithUs = () => (
  <div className="app__wrapper-reverse section__padding" id="work">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.workwithus} alt="chef_image" />
    </div>
    <div className="app__wrapper_info">
      <h1 className="headtext__cormorant">Work with us</h1>

      {/* <div className="app__chef-content">
        <div className="app__chef-content_quote">
          <p className="p__opensans">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione
            eligendi dolorum dolorem. Quas, numquam! Perspiciatis rem sint velit
            et optio hic temporibus officia voluptates iusto. Dolor facere rem
            provident molestiae.
          </p>
        </div>
      </div> */}
      <button
        type="button"
        className="custom__button"
        style={{ marginTop: '2rem' }}
      >
        Work with us
      </button>
    </div>
  </div>
);

export default WorkWithUs;
